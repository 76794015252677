<template>
  <div class="container">
    <div class="content">
      <div class="table1">
        <div class="title">
          <div class="title-top">
            <img src="../assets/yanfa/icon.png"
                 class="img-icon"
                 alt="">
            <h1 class="title-h1">R&D</h1>
          </div>
          <div class="title-bottom"
               v-if="$store.state.isZH">
            研发与创新
          </div>
        </div>
        <div class="content-table">
          <div class="left">
            <div class="left-title"
                 :class="$store.state.isZH ? '' : 'enWidth'">{{ $t('product.Veterinary') }}</div>
            <div class="left-icon"><img src="../assets/changpin/icon.png"
                   class="title-icon"
                   alt=""></div>
          </div>

          <!-- 表格 -->
          <div class="right">
            <div class="table-title">
              <el-row type="flex"
                      align="middle">
                <el-col :span="5"
                        v-if="$store.state.isZH">
                  产品名称
                </el-col>
                <el-col :span="13"
                        :class="$store.state.isZH ? '' : 'ENBorder' "
                        class="ENName">
                  Product Name
                </el-col>
                <el-col :span="6"
                        class="cas">
                  CAS No.
                </el-col>
              </el-row>
            </div>

            <div class="table-content">
              <div v-for="item in annimalList"
                   :key="item.enName">
                <div class="table-row">
                  <el-row type="flex"
                          align="middle">
                    <el-col :span="5"
                            v-if="$store.state.isZH">
                      {{ item.zhName }}
                    </el-col>
                    <el-col :span="13"
                            :class="$store.state.isZH ? '' : 'ENBorder' "
                            class="neirong">
                      {{item.enName}}
                    </el-col>
                    <el-col :span="6"
                            class="cas">
                      {{ item.cas }}
                    </el-col>
                  </el-row>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="table1">
        <div class="content-table">
          <div class="left">
            <div class="left-title">{{ $t('product.Human') }}</div>
            <div class="left-icon"><img src="../assets/changpin/user.png"
                   class="title-icon"
                   alt=""></div>
          </div>
          <div class="right">
            <div class="table-title">
              <el-row type="flex"
                      align="middle">
                <el-col :span="5"
                        v-if="$store.state.isZH">
                  产品名称
                </el-col>
                <el-col :span="13"
                        class="ENName"
                        :class="$store.state.isZH ? '' : 'ENBorder' ">
                  Product Name
                </el-col>
                <el-col :span="6"
                        class="cas">
                  CAS No.
                </el-col>
              </el-row>
            </div>

            <div class="table-content">
              <div v-for="item in humanList"
                   :key="item.enName">
                <div class="table-row">
                  <el-row type="flex"
                          align="middle">
                    <el-col :span="5"
                            v-if="$store.state.isZH">
                      {{ item.zhName }}
                    </el-col>
                    <el-col :span="13"
                            :class="$store.state.isZH ? '' : 'ENBorder' "
                            class="neirong">
                      {{item.enName}}
                    </el-col>
                    <el-col :span="6"
                            class="cas">
                      {{ item.cas }}
                    </el-col>
                  </el-row>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RandD',
  data () {
    return {
      annimalList: [
        {
          zhName: '阿法前列醇',
          enName: 'Alfaprostol',
          cas: '74176-31-1'
        },
        {
          zhName: '垂体促卵泡素',
          enName: 'Porcine Pituitary FSH',
          cas: 'N/A'
        },
        {
          zhName: '盐酸右美托咪定',
          enName: 'Dexmedetomidine Hydrochloride',
          cas: '145108-58-3'
        },
        {
          zhName: '马赛替尼',
          enName: 'Masitinib',
          cas: '790299-79-5'
        },
        {
          zhName: '氟氯苯菊酯',
          enName: 'Flumethrin',
          cas: '69770-45-2'
        },
        {
          zhName: '吡虫啉',
          enName: 'Imidacloprid',
          cas: '138261-41-3'
        },
        {
          zhName: '普拉沙星',
          enName: 'Pradofloxacin',
          cas: '195532-12-8'
        },
        {
          zhName: '罗贝考昔',
          enName: 'Robenacoxib',
          cas: '220991-32-2'
        },
        {
          zhName: '吗伐考昔',
          enName: 'Mavacoxib',
          cas: '170569-88-7'
        },
        {
          zhName: '托西尼布',
          enName: 'Toceranib',
          cas: '356068-94-5'
        },
        {
          zhName: '培福瑞林',
          enName: 'Peforelin',
          cas: '147859-97-0'
        },
        {
          zhName: '马来酸奥拉替尼',
          enName: 'Oclacitinib Maleate',
          cas: '1208319-27-0'
        },
        {
          zhName: '氟雷拉纳',
          enName: 'Fluralaner',
          cas: '864731-61-3'
        },
        {
          zhName: '沙罗拉纳',
          enName: 'Sarolaner',
          cas: '1190865-44-1'
        },
      ],
      humanList: [
        {
          zhName: '曲伏前列素',
          enName: 'Travoprost',
          cas: '157283-68-6'
        },
        {
          zhName: '醋酸阿托西班',
          enName: 'Atosiban Acetate',
          cas: '914453-95-5'
        },
        {
          zhName: '利马前列素',
          enName: 'Limaprost',
          cas: '74397-12-9'
        },
        {
          zhName: '比马前列素',
          enName: 'Bimaprost',
          cas: '155206-00-1'
        },
        {
          zhName: '卡前列素氨丁三醇',
          enName: 'Carboprost Tromethamine',
          cas: '58551-69-2'
        },
        {
          zhName: '醋酸特利加压素',
          enName: 'Terlipressin Acetate',
          cas: '14636-12-5'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background-color: rgb(129, 156, 163);
  .content {
    width: 100%;
    height: 100%;
    padding: 22% 10% 0;
    background: url("../assets/yanfa/bgc.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .table1 {
      padding-bottom: 10%;
      box-sizing: border-box;
      .title {
        color: #fff;
        padding-bottom: 8%;
        box-sizing: border-box;
        .img-icon {
          height: 65px;
          padding-right: 6%;
        }
        .title-top {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .title-h1 {
          font-size: 70px;
        }
        .title-bottom {
          display: flex;
          justify-content: flex-end;
          font-size: 47px;
          font-weight: bold;
        }
      }
      .content-table {
        display: flex;
        justify-content: space-between;
        .left {
          width: 18%;
          .left-title {
            font-size: 31px;
            color: #fff;
            padding-bottom: 20px;
          }
        }
        .right {
          font-size: 18px;
          width: 77%;
          box-sizing: border-box;
          border-top: 2px solid #fff;
          .ENBorder {
            border-left: 0 !important;
          }
          .table-title {
            color: #fff;
            font-size: 21px;
            font-weight: 600;
            height: 75px;
            border-bottom: 10px solid #fff;
            box-sizing: border-box;
            .el-row {
              width: 100%;
              height: 100%;
            }

            .ENName {
              border-left: 1px dashed #fff;
              border-right: 1px dashed #fff;
              padding-left: 2%;
              box-sizing: border-box;
              height: 70px;
              line-height: 70px;
            }
          }
          .cas {
            padding-left: 2%;
          }
          .table-content {
            color: #fff;
            .table-row {
              .el-row {
                border-bottom: 1px solid #fff;
                height: 50px;
              }
            }
            .neirong {
              display: flex;
              align-items: center;
              height: 50px;
              font-size: 18px;
              border-left: 1px dashed #fff;
              border-right: 1px dashed #fff;
              box-sizing: border-box;
              padding-left: 2%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .container {
    .content {
      padding: 22% 3%;
      background-size: 100% 50%;
      .table1 {
        .title {
          .title-top {
            .title-h1 {
              font-size: 30px !important;
            }
            .img-icon {
              width: 30px !important;
            }
          }
        }
        .content-table {
          .left {
            .enWidth {
              width: 50%;
            }
          }
          .right {
            font-size: 14px;
            .table-title {
              .el-row {
                font-size: 18px;
              }
            }
            .table-content {
              .table-row {
                .el-row {
                  height: 50px;
                }
              }
              .neirong {
                font-size: 12px;
                height: 50px;
              }
              .el-row {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    .content {
      .table1 {
        .title {
          .title-top {
            .title-h1 {
              font-size: 55px;
            }
            .img-icon {
              width: 50px;
              height: 30px;
            }
          }
          .title-bottom {
            font-size: 23px;
          }
        }
        .content-table {
          flex-direction: column;
          .right {
            width: 100%;
          }
        }
      }
    }
  }

  .container .content .table1 .content-table .left {
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 10%;
    .left-title {
      font-size: 22px;
      padding-bottom: 0;
      padding-right: 10px;
    }
    .title-icon {
      width: 30px;
      height: 30px;
    }
  }
}
</style>